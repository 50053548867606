<template>
  <lab-task> <lab-windsor-equilibrium /></lab-task>
</template>

<script>
import LabWindsorEquilibrium from '../simulations/LabWindsorEquilibrium';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';
export default defineComponent({
  name: 'WindsorEquilibriumSIM',
  components: {LabWindsorEquilibrium, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
